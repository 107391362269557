.inc-header {
    height: 32px;
    margin: 16px;
    color: #fff;
    /* color: #23231F; */
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-left: 32px;
    letter-spacing: -0.5px;
    font-family: Arial, Helvetica, sans-serif
}
.wrapper-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.site-layout-background {
    background: #fff;
}

.main-wrapper{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
}
.cnt-item{
    width: 100%;
}
.top-part{
    padding: 16px 10px;
}
.footer-part{
    padding: 10px;
    display: flex;
    justify-content: flex-end;
}
.table-row{
    font-size: 12px !important;
}

.daily-content {
    max-height: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 超出部分省略号 */
    display: -webkit-box;
    /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical;
    /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 4;
}