.title {
    font-size: 20px;
    color: #333;
}
.video-part {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f5f5f5;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #eee;
    box-sizing: border-box;
}
.video-part .title {
    display: flex;
    align-items: center;
    padding: 0 16px;
    background: white;
    height: 48px;
}
.video-part .title-tips {
    display: flex;
    align-items: center;
    padding: 0 16px;
    height: 48px;
    color: #ccc;
}
.video-part .title .text {
    margin: 0 12px;
    color: #666;
    font-size: 16px;
    flex: 1;
}
.video-result {
    margin-top: 20px;
}
.video-result .info {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.video-result .info .t1{
    font-size: 18px;
    color: #333;
    margin-right: 12px;
}
.video-result .info .t2 {
    font-size: 14px;
    color: #666;
    margin-right: 12px;
}
.input-item {
    margin-top: 24px;
    display: flex;
    align-items: center;
}
.input-item .lab {
    width: 200px;
    font-size: 16px;
    color: #666;
}
.input-item .input {
    flex: 1;
}
.input-item .ant-input-affix-wrapper {
    padding:  8px 12px;
}
.input-item .ant-input{
    padding:  8px 12px;
}
.input-item .ant-select-selector {
    padding:  6px 11px;
}
.input-item .ant-upload.ant-upload-select-picture-card {
    width: 200px;
}
.btn-area{
    margin-top: 48px;
    display:  flex;
    justify-content: center;
}
.banner-img-item {
    margin: 10px 10px 0 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #f1f1f1;
}
.banner-upload-btn {
    background: #f5f5f5;
    padding: 16px 16px;
    margin: 10px 0;
}
.banner-img-item .img img {
    object-fit: cover;
    width: 160px;
    height: 90px;
}
.banner-img-item .action {
    flex: 1;
    margin: 0 80px 0 10px;
    font-weight: bold;
}
.uploader-video-preview .video-react {
    padding-top: 0 !important;
    width: 160px;
    height: 90px;
    overflow: hidden;
}
.uploader-video-preview .video-react video{
    width: 160px !important;
    height: 90px !important;
    object-fit: contain !important;
}
.uploader-video-preview .video-react button {
    display: none;
}