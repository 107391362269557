.course-static {
    font-size: 16px;
    color: #1677ff;
    user-select: none;
    cursor: pointer;
}
.chapter-course-item{
    font-size: 12px;
    margin-top: 8px;
    padding: 4px 0;
    border-bottom: 1px dashed #eee;
    user-select: none;
}
.question-analysis-container{
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 超出部分省略号 */
    display: -webkit-box;
    /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical;
    /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 3;
}