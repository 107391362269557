.main {
	position: relative;
	width: 100vw;
	height: 100vh;
	background: #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;
    background-repeat:no-repeat;
    background-size: cover;
}

.login-form {
	background: #fff;
	width: 26%;
	position: relative;
	box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
}

.head {
	position: absolute;
	left: 0;
	right: 0;
	top: 20px;
	display: flex;
	justify-content: center;
}

.head img {
	width: 100px;
	height: 100px;
	/* padding: 10px; */
	display: block;
	background: #fff;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-o-border-radius: 50%;
	-moz-border-radius: 50%;
	/* border: 6px solid rgba(0, 0, 0, 0.1); */
}

.main h1 {
	font-size: 25px;
	color: #676767;
	font-family: 'Open Sans', sans-serif;
	font-weight: 400;
	padding-top: 130px;
	text-align: center;
}

.main form {
	width: 80%;
	margin: 0 auto;
	padding: 6% 0 9% 0;
}

.main p {
	text-align: center;
}

.main form p a {
	color: #888;
	font-family: 'Open Sans', sans-serif;
}

form p a:hover {
	color: #21A957;
}

/*-----start-responsive-design------*/
@media (max-width:1440px) {
	.login-form {
		width: 30%;
	}
}

@media (max-width:1366px) {
	.login-form {
		width: 32%;
	}
}

@media (max-width:1280px) {
	.login-form {
		width: 34%;
	}
}

@media (max-width:1024px) {
	.login-form {
		width: 45%;
	}
}

@media (max-width:768px) {
	.login-form {
		width: 59%;
	}
}

@media (max-width:640px) {
	.login-form {
		width: 63%;
	}
}

@media (max-width:480px) {
	.login-form {
		width: 74%;
	}
}

@media (max-width:320px) {
	.login-form {
		width: 85%;
	}
}
.username{
	margin-top: 10px;
}
.password{
	margin-top: 10px;
}
.submit{
	margin-top: 20px;
}

.login-wrapper .content {
    margin: 0 16px;
}

.login-wrapper .nav-bar {
    height: 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.f-dark {
    color: #21232E;
}
.f-gray {
    color: #868FA0;
}
.f-gray-light {
    color: #C8CBCF !important;
}
.f-blue {
    color: #0266FF;
}
.f-white {
    color: white;
}
.f-green {
    color: #16D964 !important;
}
.f-s-26 {
    font-size: 26px !important;
    line-height: 1.5;
    font-weight: 600 !important;
}
.f-s-20 {
    font-size: 20px !important;
    line-height: 1.5;
    font-weight: 600 !important;
}
.f-s-18 {
    font-size: 18px !important;
    line-height: 1.5;
    font-weight: 600 !important;
}
.f-m-16 {
    font-size: 16px !important;
    line-height: 1.5;
    font-weight: 500 !important;
}
.f-r-16 {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
}
.f-m-14 {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 500;
}
.f-r-14 {
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
}
.f-r-12 {
    font-size: 12px !important;
    line-height: 1.5;
    font-weight: 400 !important;
}
.f-r-10 {
    font-size: 10px;
    line-height: 1.5;
    font-weight: 400;
}
.email .input, .invite-code .input {
    border: 1px solid #D7D9DD;
    border-radius: 12px;
    padding: 12px 16px;
}
.triangle {
    transition: all 0.3s linear;
}
.next-btn {
    height: 48px !important;
}
.mt-1 {
	margin-top: 4px;
}
.mt-4 {
	margin-top: 16px;
}
.mt-10 {
	margin-top: 40px;
}