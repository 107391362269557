.static-title{
    font-size: 17px;
    font-weight: 500;
    color: #1a3353;
}
.static-value{
    font-size: 30px;
    font-weight: 700;
    color: #1a3353;
}
.static-value label{
    font-size: 17px;
    margin-left: 16px;
}
.static-tips{
    font-size: 14px;
    color: #72849a;
}
.static-tips b{
    color: #1890ff;
}
.hot-user-lab {
    max-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    /* 超出部分省略号 */
    display: -webkit-box;
    /** 对象作为伸缩盒子模型显示 **/
    -webkit-box-orient: vertical;
    /** 设置或检索伸缩盒对象的子元素的排列方式 **/
    -webkit-line-clamp: 1;
    word-break: break-all;
}